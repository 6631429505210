<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.001 512.001"
    style="enable-background:new 0 0 512.001 512.001;"
    xml:space="preserve"
  >
    <path
      style="fill:#F8C889;"
      d="M256.001,512L256.001,512c-16.976,0-30.738-13.762-30.738-30.738V373.981
	c0-16.976,13.762-30.738,30.738-30.738l0,0c16.976,0,30.738,13.762,30.738,30.738v107.281C286.738,498.238,272.976,512,256.001,512z
	"
    />
    <path
      style="fill:#EFBE84;"
      d="M243.293,481.262V373.981c0-13.838,9.146-25.538,21.723-29.391c-2.851-0.873-5.877-1.346-9.015-1.346
	l0,0c-16.976,0-30.738,13.762-30.738,30.738v107.281c0,16.976,13.762,30.738,30.738,30.738l0,0c3.138,0,6.163-0.472,9.015-1.346
	C252.439,506.801,243.293,495.1,243.293,481.262z"
    />
    <circle style="fill:#FED159;" cx="256.001" cy="155.503" r="155.503" />
    <path
      style="fill:#F8C889;"
      d="M296.995,305.066h-81.99c-11.816,0-21.393,9.578-21.393,21.393l0,0
	c0,11.816,9.578,21.393,21.393,21.393h81.99c11.816,0,21.393-9.578,21.393-21.393l0,0
	C318.389,314.645,308.811,305.066,296.995,305.066z"
    />
    <path
      style="fill:#EFBE84;"
      d="M224.919,326.459L224.919,326.459c0-11.816,9.578-21.394,21.394-21.394h-31.308
	c-11.816,0-21.394,9.578-21.394,21.393l0,0c0,11.816,9.578,21.393,21.394,21.393h31.308
	C234.498,347.854,224.919,338.275,224.919,326.459z"
    />
    <circle style="fill:#A0DBFD;" cx="256.001" cy="155.503" r="114.001" />
    <path
      style="fill:#C1E9FD;"
      d="M331.97,155.5c0,56.479-41.077,103.356-94.983,112.409c6.184,1.038,12.534,1.588,19.013,1.588
	c62.958,0,113.996-51.038,113.996-113.996S318.958,41.505,255.999,41.505c-6.479,0-12.829,0.548-19.013,1.588
	C290.893,52.143,331.97,99.021,331.97,155.5z"
    />
    <path
      style="fill:#FBB53A;"
      d="M256.001,278.138c-67.623,0-122.638-55.015-122.638-122.638S188.378,32.861,256.001,32.861
	s122.638,55.015,122.638,122.638S323.623,278.138,256.001,278.138z M256.001,50.145c-58.093,0-105.354,47.262-105.354,105.354
	s47.262,105.354,105.354,105.354s105.354-47.262,105.354-105.354S314.093,50.145,256.001,50.145z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
